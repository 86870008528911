import { extend } from 'vee-validate';
import { between, numeric, required } from 'vee-validate/dist/rules';

extend('max_alert_count', {
  ...between,
  message: 'Alert count must be between 0 - 5'
});

extend('numeric', numeric);
extend('required', required);

export const maxAlertCount = {
  max_alert_count: [0, 5],
  numeric: true,
  required: true
};
